import React from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import TechnologiesCarousel from '../TechnologiesCarousel/index';

const TechnologiesMasterSection = React.memo(
  function TechnologiesMasterSection({ data, primaryProgramID, ...props }) {
    return (
      <SectionLayout
        heading={<>Technologies you will master hands-on</>}
        description={<></>}
        className="text-white"
        {...props}
      >
        <div className="container">
          <div className=" hidden flex-wrap items-center justify-center gap-6 gap-x-8 sm:flex">
            {data.map((ele, idx) => {
              const image = getImage(ele.image.childImageSharp);
              return (
                <div key={idx}>
                  <Card
                    image={image}
                    name={ele.name}
                    primaryProgramID={primaryProgramID}
                  ></Card>
                </div>
              );
            })}
          </div>
          <div className=" sm:hidden">
            <TechnologiesCarousel
              data={data}
              primaryProgramID={primaryProgramID}
              Card={Card}
            ></TechnologiesCarousel>
          </div>
        </div>
      </SectionLayout>
    );
  },
);

export default TechnologiesMasterSection;

const Card = ({ image, name, primaryProgramID }) => {
  return (
    <div className="w-[120px]">
      <GatsbyImage
        loading="eager"
        image={image}
        alt={'technology'}
        className="z-10"
        style={{ maxHeight: '100%' }}
        imgStyle={{ objectFit: 'contain' }}
      />
      <div className="z-0 -mt-10 rounded-b-[20px] bg-v5-neutral-200 p-3  text-center text-black">
        <div className="pt-9">
          <h6
            className={`flex items-center justify-center text-sm font-semibold ${
              primaryProgramID == 'QA_AUTOMATION_TESTING' && 'min-h-[40px]'
            }`}
          >
            {name}
          </h6>
        </div>
      </div>
    </div>
  );
};
