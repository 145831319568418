import React from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import { StaticImage } from 'gatsby-plugin-image';
import useDataScienceProgram from '@src/hooks/useDataScienceProgram';

const CrioComparisionSection = React.memo(function CrioComparisionSection({
  desktopImage,
  mobileImage,
  isTypeQA,
  ...props
}) {
  const { isDataSciencePage } = useDataScienceProgram();

  return (
    <SectionLayout
      heading={
        <div className="text-xl sm:text-4xl">
          Real work experience, Real project-based learning with Crio{' '}
        </div>
      }
      description={
        isDataSciencePage ? (
          <>
            The best way to learn a skill is by applying what you learn in real
            scenarios. At Crio, we take it a notch higher by giving you actual
            work experience-based learning. So, instead of just watching
            tutorials, you actually experience analysing and solving complex
            problems like professional analysts. <br></br>Basically, how a real
            professional working in a leading tech company would grow their
            career.
          </>
        ) : (
          <>
            The best way to learn a skill is by applying what you learn in real
            scenarios. At Crio, we take it a notch higher by giving you actual
            work experience-based learning. So, instead of just watching
            tutorials, you actually experience building real products like
            professional engineers.<br></br>
            <br></br> Basically, how a real professional working in a leading
            product company would grow their career.<br></br>
          </>
        )
      }
      {...props}
    >
      <div className="container">
        <div className="mb-10 flex justify-center overflow-hidden rounded-[20px]">
          <div className="hidden max-w-[900px] sm:block">
            {desktopImage ? (
              desktopImage
            ) : isDataSciencePage ? (
              <StaticImage
                width={1920}
                loading="lazy"
                src="../../../images/v5/CrioComparision/Table-Desktop-DS.webp"
                placeholder="blurred"
                alt="A table displaying different course USPs, illustrating how Crio.Do's Data Analytics program excels compared to competitors."
              />
            ) : isTypeQA ? (
              <StaticImage
                width={1920}
                loading="lazy"
                src="../../../images/v5/CrioComparision/Table-Desktop-QA.webp"
                placeholder="blurred"
                alt="A table displaying different course USPs, illustrating how Crio.Do's QA program excels compared to competitors."
              />
            ) : (
              <StaticImage
                width={1920}
                loading="lazy"
                src="../../../images/v5/CrioComparision/Table-Desktop.webp"
                placeholder="blurred"
                alt="A table displaying different course USPs, illustrating how Crio.Do's Fellowship program excels compared to competitors."
              />
            )}
          </div>
          <div className="max-w-full sm:hidden ">
            {mobileImage ? (
              mobileImage
            ) : isDataSciencePage ? (
              <StaticImage
                width={1000}
                loading="lazy"
                src="../../../images/v5/CrioComparision/Table-Mobile-DS.webp"
                placeholder="blurred"
                alt="A table displaying different course USPs, illustrating how Crio.Do's Data Analytics program excels compared to competitors."
              />
            ) : isTypeQA ? (
              <StaticImage
                width={1000}
                loading="lazy"
                src="../../../images/v5/CrioComparision/Table-Mobile-QA.webp"
                placeholder="blurred"
                alt="A table displaying different course USPs, illustrating how Crio.Do's QA program excels compared to competitors."
              />
            ) : (
              <StaticImage
                width={1000}
                loading="lazy"
                src="../../../images/v5/CrioComparision/Table-Mobile.webp"
                placeholder="blurred"
                alt="A table displaying different course USPs, illustrating how Crio.Do's Fellowship program excels compared to competitors."
              />
            )}
          </div>
        </div>
        <p
          className={`${
            props.dark ? 'text-v5-neutral-300' : 'text-v5-neutral-400'
          } text-center leading-loose sm:px-20`}
        >
          {' '}
          Recruiters too, look for real project experience when hiring tech
          professionals and here at Crio, we strive to provide just that. We
          empower learners with high-quality applied learning opportunities and
          build skills that translate into career growth and success.
        </p>
      </div>
    </SectionLayout>
  );
});

export default CrioComparisionSection;
